* {
  --brand-color-1: #ab4131;
  --brand-color-2: #b6644b;
  --brand-color-3: #cf9a7a;
  --brand-color-4: #fcb8b8;
  --brand-color-5: #b6644b;
}

html,
body,
#root,
.App,
#root > div,
#app,
.statsContainer,
#app > div {
  height: 100%;
}

button {
  border: none;
  padding: 10px;
  padding-left: 55px;
  padding-right: 55px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: large;
  cursor: pointer;
  background-color: #b6644b;
  color: white;
}

button:hover {
  background-color: #b6644b96;
}