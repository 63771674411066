@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;600;700&display=swap");

body {
  margin: 0;
  font-family: bebas;
  /* font-family: "Cinzel", serif;  */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inconsolata", monospace;
}

html,
body,
#root,
.App,
#root > div,
#app,
#app > div {
  height: 100%;
}
