.buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.container {
  height: 1000px;
}

.choice_list {
  height: 800px;
}

.body {
  display: flex;
  justify-content: center;
  background-color: #f7f7f7;
}

.btn_confirm {
  color: white;
  background-color: var(--brand-color-2);
}

.btn_confirm:hover {
  background-color: #b6644bb3;
}

.btn_back {
  background-color: rgb(221, 218, 218);
  color: #333;
}

.btn_back:hover {
  background-color: rgba(221, 218, 218, 0.726);
}

@media (max-width: 700px) {
}

/* .btn {
  border: none;
  padding: 10px;
  padding-left: 55px;
  padding-right: 55px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: large;
  cursor: pointer;
} */
