.title {
  color: var(--brand-color-3);
  text-align: center;
}

.text {
  color: rgba(0, 0, 0, 0.644);
}

.description__text {
  color: white;
}

.text_container {
  width: 50%;
}

.div_container {
  display: flex;
  justify-content: center;
}

.button {
  border: none;
  padding: 15px;
  background-color: var(--brand-color-1);
  color: #fff;
  cursor: pointer;
  width: 50%;
  border-radius: 2px;
  font-size: medium;
}

.button:hover {
  background-color: #ab4131c4;
}

.body {
  display: flex;
  justify-content: center;
}

.container {
  height: 100%;
  background-image: url("../../../public//background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.allContainer {
  height: 100%;
}

.button_div {
  display: flex;
  justify-content: center;
}
